"use client"

import type { I18nLocale } from "@/modules/i18n/types"
import type { FunctionComponent } from "react"

import classNames from "classnames"

import { Link } from "@/modules/routing/components/Link"
import { getUpdatedHrefWithLocale } from "@/modules/routing/getUpdatedHrefWithLocale"
import { useHref } from "@/modules/routing/hooks/useHref"

import styles from "./LocaleSelector.module.css"

type ListProps = {
  locale: I18nLocale
  localesToDisplay: I18nLocale[]
}

export const List: FunctionComponent<ListProps> = ({ locale, localesToDisplay }) => {
  const href = useHref()

  return (
    <div>
      {localesToDisplay.map(localeToDisplay => (
        <Link
          href={getUpdatedHrefWithLocale(href, locale, localeToDisplay)}
          className={classNames(styles.linkLang, {
            [styles.linkLang__active]: locale === localeToDisplay,
          })}
          key={localeToDisplay}
          rel="nofollow"
          prefetch={false}
        >
          {localeToDisplay.toUpperCase()}
        </Link>
      ))}
    </div>
  )
}
