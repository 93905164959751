import type { FunctionComponent } from "react"

// The usage of next/link is explicitely needed here.
// eslint-disable-next-line no-restricted-imports
import NextLink from "next/link"

import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"

export type LinkProps = Omit<Parameters<typeof NextLink>[0], "href" | "locale"> & { href: string }

// The prefetch is disabled by default to avoid extra page load.
// The locale feature is not used
export const Link: FunctionComponent<LinkProps> = ({ prefetch = false, ...props }) => {
  const { href } = props

  if (href !== "/" && !AVAILABLE_LOCALES.find(availableLocale => href.startsWith(`/${availableLocale}`))) {
    throw Error(`The internal link must start with an available locale href="${href}".`)
  }

  return <NextLink {...props} prefetch={prefetch} />
}
