import type { I18nLocale } from "@/modules/i18n/types"

/**
 * This method is used to update the locale of a string or UrlObject href.
 * It returns a string since it is the prefered format of next/link.
 */
export function getUpdatedHrefWithLocale(href: string, previousLocale: I18nLocale, newLocale: I18nLocale): string {
  const previousLocaleRegExp = new RegExp(`^/${previousLocale}`)

  return href.replace(previousLocaleRegExp, `/${newLocale}`) || `/${newLocale}`
}
